import React, { useRef } from 'react';
import styles from './Home.module.css';
import InfoHeader from '../components/InfoHeader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import mobileNotaryIcon from '../images/mobile-notary-icon.png';
import businessDocumentsIcon from '../images/business-documents-icon.png';
import realEstateIcon from '../images/real-estate-icon.png';
import checkBox from '../images/check-box.svg';
import badge1 from '../images/badge1.png';
import badge2 from '../images/badge2.png';
import badge3 from '../images/badge3.png';
import badge4 from '../images/badge4.png';
import { HashLink as Link} from 'react-router-hash-link';
import { motion } from 'framer-motion';
import { Fade } from 'react-awesome-reveal';


function Home() {

    const topRef = useRef(null);

    return (
        <motion.div
        initial={{opacity: 0 }}
        animate={{opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
            <section ref={topRef} className={styles["hero-container"]}>
                <div className={styles["hero-section"]}>
                    <InfoHeader />
                    <Header />
                    <section className={styles["hero-content"]}>
                        <motion.div
                            initial={{ x:-100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ duration: 1, delay: 0.5 }}>
                        
                            <h1 className={styles["heading"]}>Bringing notary services to you</h1>
                        </motion.div>
                        
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ duration: 1, delay: 1 }}
                        >
                            <div className={styles["hero-text"]}>
                                <p className={styles["paragraph"]}>
                                Since 2019, Driven Mobile Notary Services has valued itself in providing clients with professional, 
                                efficient, knowledgeable, and reliable notary services. We promise to provide a stress-free, quality, and trustworthy notary experience. 
                                We take great care in ensuring every individual is treated with respect and 
                                professionalism, appreciating the opportunity to serve you with each service we offer. 
                                At Driven Mobile Notary Services, ensuring your satisfaction isn't just our goal; it's what drives us.
                                <br></br>
                                <br></br>
                                Book your appointment today!
                                </p>    
                            </div>
                        </motion.div> 

                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 1.2 }}
                        >
                            <div className={styles["learn-more"]}>
                            <Link to="/about" className={styles["learn-btn"]}>Learn More</Link>
                            </div>
                        </motion.div>
                    </section>
                </div>
            </section>

            <section className={styles["services-section"]}>
                <h3 className={styles["services-heading"]}>Our Services</h3>
                <h2 className={styles["services-subheading"]}>What we do</h2>
                <div className={styles["services-cards"]}>

                    <Fade delay={200}
                    duration={2000}
                    triggerOnce={true} 
                    >
                        <div className={styles["mobile-card"]}>
                            <img className={styles["notary-icon"]} src={mobileNotaryIcon} alt="icon of truck with letter to depict mobile notary"></img>
                            <p className={styles["card-title"]}>Mobile Notary</p>
                            <p className={styles["card-description"]}>
                                We bring professional notarization directly to you, 
                                wherever you are, ensuring efficiency and ease for all your notary needs!
                            </p>
                        </div>
                    </Fade>

                    <Fade delay={400}
                    duration={2000}
                    triggerOnce={true} 
                    >
                        <div className={styles["business-card"]}>
                            <img className={styles["business-icon"]} src={businessDocumentsIcon} alt="icon of notary contract with stamp"></img>
                            <p className={styles["card-title"]}>General Notary Services</p>
                            <p className={styles["card-description"]}>
                            Expertly notarizing a variety of documents, our service covers everything from wills, 
                            affidavits, and power of attorney to contracts and legal statements
                            </p>
                        </div>
                    </Fade>

                    <Fade delay={600}
                    duration={2000}
                    triggerOnce={true} 
                    >
                        <div className={styles["realestate-card"]}>
                            <img className={styles["realestate-icon"]} src={realEstateIcon} alt="icon of house with contract behind it"></img>
                            <p className={styles["card-title"]}>Loan Signing</p>
                            <p className={styles["card-description"]}>
                            We offer notarization for a variety of loan-related documents, such as mortgages, 
                            refinancing agreements, HELOC, and property purchases
                            </p>
                        </div>
                    </Fade>
                </div>

                <Link to="/services" className={styles["view-all"]}>View all services</Link>    
            </section>

            <section className={styles["service-areas-section"]}>
                <h3 className={styles["area-heading"]}>Service Areas</h3>
                <h2 className={styles["area-heading-two"]}>Nevada</h2>
                <div className={styles["area-cities"]}>
                        <p className={styles["area-city"]}>Las Vegas</p>
                        <p className={styles["area-city"]}>Henderson</p>
                        <p className={styles["area-city"]}>North Las Vegas</p>
                        <p className={styles["area-city"]}>Pahrump</p>
                        <p className={styles["area-citylast"]}>Primm</p>
                </div>
                <div className={styles["area-cards"]}>
                    

                    <Fade delay={200}
                        duration={2000}
                        triggerOnce={true} 
                    >
                        <div className={styles["zone-one"]}>
                            <p className={styles["zone-title"]}>Pricing</p>
                            <div className={styles["blue-line"]}></div>

                            <div className={styles["item-list"]}>
                                <div className={styles["item-one"]}>
                                    <img className={styles["checkbox-icon"]} src={checkBox} alt="checkbox"></img>
                                    <p className={styles["item"]}>Fees for notarization <span className={styles["document-type"]}>(Determined by document type)</span></p>
                                </div>
                                
                                <div className={styles["item-two"]}>
                                    <img className={styles["checkbox-icon"]} src={checkBox} alt="checkbox"></img>
                                    <p className={styles["item"]}>Minimum 2 hours (6am - 7pm) - $30</p>
                                </div>
                            
                                <div className={styles["item-three"]}>
                                    <img className={styles["checkbox-icon"]} src={checkBox} alt="checkbox"></img>
                                    <p className={styles["item"]}> Additional fees may apply if travel exceeds 2 hours</p>
                                </div>

                                

                                <div className={styles["price"]}>
                                    <p className={styles["amount"]}>$30</p>
                                    <p className={styles["fee-amount"]}>+ Notarization fees</p>
                                
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade delay={500}
                        duration={2000}
                        triggerOnce ={true}    
                    >
                        <div className={styles["zone-two"]}>
                            <p className={styles["zone-title"]}>Pricing (After hours)</p>
                            <div className={styles["blue-line"]}></div>
                            <div className={styles["item-list"]}>
                                <div className={styles["item-one"]}>
                                    <img className={styles["checkbox-icon"]} src={checkBox} alt="checkbox"></img>
                                    <p className={styles["item"]}>Fees for notarization <span className={styles["document-type"]}>(Determined by document type)</span></p>
                                </div>
                                
                                <div className={styles["item-two"]}>
                                    <img className={styles["checkbox-icon"]} src={checkBox} alt="checkbox"></img>
                                    <p className={styles["item"]}>Minimum 2 hours (7pm - 6am) - $60</p>
                                </div>

                                <div className={styles["item-three"]}>
                                    <img className={styles["checkbox-icon"]} src={checkBox} alt="checkbox"></img>
                                    <p className={styles["item"]}>Additional fees may apply if travel exceeds 2 hours</p>
                                </div>

                                <div className={styles["price"]}>
                                    <p className={styles["amount"]}>$60</p>
                                    <p className={styles["fee-amount"]}>+ Notarization fees</p>
                                
                                </div>
                            </div>
                        </div>
                    </Fade>

                </div>

                
                <Fade delay={500}
                    duration={2000}
                    triggerOnce ={true}    
                >
                    <div className={styles["services-learn-more"]}>
                        <Link to="/pricing" className={styles["services-btn"]}>Learn More</Link>
                    </div>
                </Fade>

            </section>

            <section className={styles["reviews-container"]}>
                <div className={styles["customer-reviews"]}>
                    <Fade delay={200}
                    duration={2000}
                    triggerOnce ={true}    
                    >
                        <h2 className={styles["reviews-heading"]}>Customer Reviews</h2>
                    </Fade>
                    
                    <Fade delay={300}
                    duration={2000}
                    triggerOnce ={true}    
                    >
                        <div className={styles["reviews-container"]}>
                            <div className="elfsight-app-13b94dab-87ec-4493-bd40-0e465f06a292" data-elfsight-app-lazy></div>
                        </div>
                    </Fade>

    
                    <div className={styles["badges"]}>
                    <Fade delay={400}
                    duration={2000}
                    triggerOnce ={true}    
                    >
                        <img src={badge1} className={styles["badge-one"]} alt="NNA icon"></img>
                        <img src={badge2} className={styles["badge-two"]} alt="NNA icon"></img>
                        <img src={badge3} className={styles["badge-three"]} alt="NNA icon"></img>
                        <a href="https://www.bbb.org/us/nv/north-las-vegas/profile/notary-public/driven-mobile-notary-services-1086-90086593/#sealclick" target="_blank" rel="noreferrer">
                            <img src={badge4} className={styles["badge-four"]} alt="BBB icon"></img>
                        </a>
                    </Fade>
                    </div> 
                </div>   
            </section>
            

            <section className={styles["book-appointment-section"]}>
                
                <div className={styles["appointment-content"]}>

                    <Fade delay={200}
                        duration={2000}
                        triggerOnce ={true}    
                    >
                    <h2 className={styles["book-app-title"]}>Stay Put, We're in Route!</h2>
                    </Fade>

                    <Fade delay={300}
                        duration={2000}
                        triggerOnce ={true}    
                    >
                    <p className={styles["book-app-text"]}>Life can get busy, and we understand that. 
                    Why scramble to find a notary when we can come to you? <span className={styles["blue-text"]}>Driven Mobile Notary Services</span> ensures you can get your documents notarized at your convenience, <span className={styles["blue-text"]}>right at your doorstep</span>. 
                    <br></br>
                    <br></br>
                    Schedule your appointment today!
                    </p>
                    </Fade>

                    <Fade delay={400}
                        duration={2000}
                        triggerOnce ={true}    
                    >
                        <div className={styles["book-appointment"]}>
                            <a href="https://drivennotaryservices.com/booking/schedule" target="_blank" className={styles["book-btn"]} rel="noreferrer">Book Appointment</a>
                        </div>
                    </Fade>
                </div>
            </section>

            <section className={styles["footer-section"]}>
                <Footer topRef={topRef} />
            </section>
        </motion.div>
    )
}

export default Home;
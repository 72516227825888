import React from "react";
import styles from './InfoHeader.module.css'
import phone from '../images/phone.svg';
import clock from '../images/clock.svg';

const InfoHeader = () => {
    return (
        <div className={styles["info-container"]}>
            <div className={styles["phone"]}>
                <img className={styles["phone-icon"]} src={phone}></img>
                <p className={styles["number"]}>(702) 902-9550</p>
            </div>  

            <div className={styles["business-hours"]}>
                <img className={styles["clock-icon"]} src={clock}></img>
                <p className={styles["hours"]}>
                    Mon-Fri: 8:00AM - 8:00PM |
                    Sat: 9:00AM - 5:00PM | Sun: Closed
                </p>
            </div>
        </div>

    )
}

export default InfoHeader;
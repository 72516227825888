import React, { useState } from 'react';
import styles from './FrequentlyAskedQuestions.module.css';

const faqData = [
  {
    question: 'What is a notary?',
    answer: (
        <p className={styles["answer"]}>
            The National Notary Association (NNA) defines a Notary Public as “an official of integrity appointed by the state government — typically by the secretary of state — 
            to serve the public as an impartial witness in performing a variety of official fraud-deterrent acts related to the signing of important documents.” 
            <a className={styles["click-here"]} href="https://www.nationalnotary.org/knowledge-center/about-notaries/what-is-a-notary-public" target="_blank" rel="noopener noferrer"> Click here</a> to learn more.
        </p>
    ),
  },

  {
    question: 'What is a notary signing agent?',
    answer: (
        <p className={styles["answer"]}>
            According to the National Notary Association, “a Notary Signing Agent is a Notary who has been trained to handle loan documents. 
            Lenders and title companies hire Signing Agents as independent contractors to assist in the last step of the loan process.” 
            <a className={styles["click-here"]} href="https://www.nationalnotary.org/knowledge-center/signing-agent-resources/what-is-a-notary-signing-agent" target="_blank" rel="noopener noferrer"> Click here</a> to learn more.
        </p>
    )
  },
  {
    question: 'What is an E-Notary Public?',
    answer: (
        <p className={styles["answer"]}>
            Driven Mobile Notary Services has added another convenient service that will make notarization even faster and more efficient!
            E-notarization provides many convenient services for those clients unable to have documents notarized in person.
            <br></br>
            <br></br>
            According to <a href="nvsos.gov" className={styles["click-here"]}>nvsos.gov</a>, an electronic notarial act is any act that an electronic notary public of this state is authorized to perform, including taking an acknowledgment, 
            executing a jurat or certifying a copy that is performed using electronic tools or over the internet via digital tools and a live audio-video call.
            <a className={styles["click-here"]} href="https://www.nvsos.gov/sos/licensing/notary/enotary-faqs" target="_blank" rel="noopener noferrer"> Click here</a> to learn more.
        </p>
    )
  },
  {
    question: 'What is RON (Remote Online Notarization)?',
    answer: (
        <p className={styles["answer"]}>
            The National Notary Association describes Remote Online Notarization as, "a signer personally appears before the Notary at the time of the notarization using audio-visual 
            technology over the internet instead of being physically present in the same room. 
            Remote Online Notarization is also called webcam notarization, online notarization, or virtual notarization.”
            <a className={styles["click-here"]} href="https://www.nationalnotary.org/notary-bulletin/blog/2018/06/remote-notarization-what-you-need-to-know" target="_blank" rel="noopener noferrer"> Click here</a> to learn more.

        </p>
    )
  }
];

const FAQItem = ({ faq, index, activeIndex, setActiveIndex }) => {
  const isActive = index === activeIndex;

  const handleClick = () => {
    if (isActive) {
      setActiveIndex(null); // Collapse the current item
    } else {
      setActiveIndex(index); // Expand the clicked item
    }
  };

  const faqQuestionStyle = {
    borderBottomLeftRadius: isActive ? '0px' : '5px',
    borderBottomRightRadius: isActive ? '0px' : '5px',
  };

  return (
    <div className={styles.faqItem}>
      <button className={styles.faqQuestion} style={faqQuestionStyle} onClick={handleClick}>
        {faq.question}
        <span className={isActive ? styles.arrowUp : styles.arrowDown} />
      </button>
      {isActive && <div className={styles.faqAnswer}>{faq.answer}</div>}
    </div>
  );
};

const FrequentlyAskedQuestions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <section className={styles.frequentlyAskedQuestions}>
      <h3 className={styles.faqTitle}>Frequently Asked Questions</h3>
      {faqData.map((faq, index) => (
        <FAQItem
          key={index}
          faq={faq}
          index={index}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      ))}
    </section>
  );
};

export default FrequentlyAskedQuestions;

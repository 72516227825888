import React from 'react';
import styles from './About.module.css';
import InfoHeader from '../components/InfoHeader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import personConsulting from '../images/personConsulting.png';
import personNotaryStamp from '../images/personNotaryStamp.png';


function About() {
    return (
        <>
            <motion.div
            initial={{opacity: 0 }}
            animate={{opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}>
                <InfoHeader/>
                <Header/>
            
                <div className={styles["main-container"]}>
                    <h1 className={styles["about-title"]}>About Us</h1>

                    <section className={styles["about-us"]}>
                        <div className={styles["about-content"]}>
                            <div className={styles["column-one"]}>
                                <img src={personNotaryStamp} className={styles["img-1"]}></img>
                                <p className={styles["paragraph-one"]}>At Driven Mobile Notary Services (DMNS), 
                                we are dedicated to providing notarial services that exemplify the highest standards of 
                                professionalism, efficiency, and security. 
                                In an era where rapidity, accuracy, and convenience are highly valued, 
                                we at DMNS go a step further by placing a strong emphasis on expertise and 
                                precision in our notary practices. We recognize the importance of time, 
                                yet we firmly believe that the integrity and accuracy of our services are paramount. 
                                </p>
                                
                            </div>


                            <div className={styles["column-two"]}>
                                <img src={personConsulting} className={styles["img-2"]}></img>   
                                <p className={styles["paragraph-two"]}>We are unwavering in our commitment to providing our clients with notary services that are not only convenient 
                                but also highly accommodating. Understanding the importance of flexibility and security, 
                                we offer the unique service of traveling to a location of our clients' choosing, 
                                be it their residence, office, or any place where they feel most comfortable and secure. 
                                In line with our dedication to safety, we rigorously adhere to COVID-19 safety protocols to ensure the well-being of our clients and staff.
                                <br></br>
                                <br></br>
                                 
                                We are devoted to maintaining the highest standards of confidentiality and security, 
                                ensuring that our clients’ sensitive information is handled with the utmost care and discretion. 
                                Through our meticulous approach and attentive service, we strive to instill a sense of confidence and assurance in every interaction, ensuring that our clients’ notarial requirements are met with professionalism and integrity.</p>
                            </div>
                        </div>
                    </section>

                    <section className={styles["footer-section"]}>
                        <Footer/>
                    </section>
                </div>



            </motion.div>
        </>
    )
}

export default About;
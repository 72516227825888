import React, {useRef} from 'react';
import styles from './Pricing.module.css'
import InfoHeader from '../components/InfoHeader';
import Header from '../components/Header'
import customerPayment from '../images/manWithCard.png';
import Footer from '../components/Footer'
import { motion } from 'framer-motion';


function Pricing() {
    const topRef = useRef(null);

    return (
        <>
            <motion.div
            initial={{opacity: 0 }}
            animate={{opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}>
                <InfoHeader ref={topRef}/>
                <Header/>
                <div className={styles["main-container"]}>
                    
                    <h1 className={styles["pricing-title"]}>Pricing and Fees</h1>
                    
                    <section className={styles["fees-introduction"]}>
                        <p className={styles["fees-disclaimer"]}>
                            All fees prescribed in this section are payable in advance. 
                            <br></br>
                            Travel fees are not included and must be agreed upon by the client 
                            before notarization with the client. 
                            <br></br>
                            <a href="https://www.leg.state.nv.us/nrs/nrs-240.html" className={styles["pricing-link"]} target="_blank" rel="noopener noferrer"> Click here</a> for more information.
                        </p>
                    
                    </section>

                    <section className={styles["NRS-fees"]}>

                        <div className={styles["column-wrapper"]}>
                            <div className={styles["column-one"]}>
                                <h4 className={styles["sub-title"]}>Per NRS 240.100</h4>

                                <p>For taking an acknowledgment, for the first signature of each signer - $15.00</p>
                                <p>For each additional signer - $7.50</p>
                                <p>For administering an oath or affirmation without a signature - $7.50</p>
                                <p>For a certified copy - $7.50</p>
                                <p>For a jurat, for each signature on the affidavit - $15.00</p>

                                <h4 className={styles["sub-title"]}>Per NRS 240.100 (d)(1)(2)</h4>
                            
                                <p className={styles["additional-fee"]}>The Additional Fee for Travel Cannot Exceed: </p>
                            
                                <p>If the person requesting the notarial act asks the notary public to travel between the hours of: </p>
                        
                                <p className={styles["hourly-fee"]}>6 am and 7 pm - $15 per hour</p>

                                <p> If the person requesting the notarial act asks the notary public to travel between the hours of:</p>

                                <p className={styles["hourly-fee"]}>7 pm and 6 am - $30 per hour</p>

                                <p className={styles["minimum-charge"]}>The notary public may charge a minimum of 2 hours for such travel and shall charge on a pro-rated basis (for each hour following) after the first 2 hours.</p>
                            </div>

                            <div className={styles["column-two"]}>
                                <img src={customerPayment} className={styles["payment-image"]}></img>
                                
                            </div>
                        </div>
                        
                    </section>

                    <section className={styles["cancellation-terms"]}>
                        <h4 className={styles["sub-title"]}>Cancellation Terms</h4>
                        <p className={styles["appointment-disclaimer"]}>Appointments will need to be cancelled within the first hour of their scheduled appointment.</p>
                        <p className={styles["cancellation-disclaimer"]}><span className={styles["note-span"]}>NOTE: </span>If the person requesting the notarization cancels the request after the notary public begins traveling to perform, the requested notarial act will be charged the agreed-upon travel fee Per NRS 240.100 (4)(a)(b). 
                        <br></br>                    
                        <a href="https://www.leg.state.nv.us/nrs/nrs-240.html" className={styles["cancellation-link"]} target="_blank" rel="noopener noferrer"> Click here</a> for more information. </p>
                    </section>

                    <section className={styles["footer-section"]}>
                        <Footer/>
                    </section>

                </div>
            </motion.div>
        </>
    )
}

export default Pricing;
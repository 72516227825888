import React from 'react';
import './index.css';
import Home from './pages/Home';
import Services from './pages/Services';
import Pricing from './pages/Pricing';
import About from './pages/About';
import Contact from './pages/Contact';
import ScrollToTop from './components/ScrollToTop';
import { Routes, Route } from 'react-router-dom';


function App() {
  
  return (
    <>
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/services'
        element={<Services/>} />
        <Route path='/pricing'
        element={<Pricing/>} />
        <Route path='/about'
        element={<About/>} />
        <Route path='/contact'
        element={<Contact/>} />
    </Routes>
  </>
  );
}

export default App;

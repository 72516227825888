import React, { useState, useEffect, useRef } from 'react';
import styles from './Sidebar.module.css';
import { Squash as Hamburger } from 'hamburger-react';
import gsap from 'gsap';
import { Fade } from 'react-awesome-reveal';
import { NavLink, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const [isOpen, setOpen] = useState(false);
  const sidebarRef = useRef(null);
  const hamburgerRef = useRef(null);


  const toggleSidebar = () => {
    setOpen(!isOpen);
  };


  return (
    <>
        <header className={styles["menu-button-container"]}>
            <div className={styles['menu-button']} ref={hamburgerRef}>
                <Hamburger
                size={40}
                toggled={isOpen}
                toggle={toggleSidebar}
                color={isOpen ? '#ffffff' : '#333333'}
                direction="left"
                distance="sm"
                rounded
                />
            </div>
        </header>
        
        <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`} ref={sidebarRef}>

        
        <nav className={styles.menu}>
            <ul>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/services">Services</NavLink></li>
            <li><NavLink to="/pricing">Pricing</NavLink></li>
            <li><NavLink to="/about">About Us</NavLink></li>
            <li><NavLink to="/contact">Contact</NavLink></li>
            <li><a href="https://drivennotaryservices.com/booking" target="blank"><button className={styles["book-app-btn"]}>Book Appointment</button></a></li>
            </ul>
        </nav>
        </div>
    </>
    
  );
};

export default Sidebar;

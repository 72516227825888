import React, { useRef } from 'react';
import styles from './Services.module.css';
import InfoHeader from '../components/InfoHeader';
import Header from '../components/Header';
import FrequentlyAskedQuestions from '../components/FrequentlyAskedQuestions';
import Footer from '../components/Footer';
import ElderlyCoupleImage from '../images/elderlyCoupleSigning.png';
import WomanSigningImage from '../images/womanSigning.png';
import { motion } from 'framer-motion';


function Services () {

    const topRef = useRef(null);

    return (
        <motion.div
        initial={{opacity: 0 }}
        animate={{opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
            <InfoHeader ref={topRef}/>
            <Header/>
            
            <div className={styles["main-container"]}>
                <h1 className={styles["services-title"]}>Services</h1>

                
                <section className={styles["general-notary-work"]}>
                    <h3 className={styles["services-sub-title"]}>General Notary Work Services</h3>
                    <p className={styles["paragraph"]}>
                    Driven Mobile Notary Services (DMNS) thrives to be the best in providing professional, 
                    efficient, knowledgeable, and reliable notary services to its customers. 
                    We are driven to provide our clients with excellent and dependable services. 
                    It is important that our clients understand exactly what type of notarial documents a notary can notarize. 
                    DMNS promises to give each client their complete attention with their notary needs; 
                    there is no “rush job” when it comes to our services.
                    </p>

                    <h4 className={styles["offered-title"]}>Services offered:</h4>

                    <div className={styles["column-container"]}>
                        <div className={styles["column-one"]}>
                            <h5 className={styles["sub-title"]}>Acknowledgements</h5>
                            <p className={styles["description"]}>
                            The purpose of an acknowledgment is to ensure that the document’s signer is who they claim to be and 
                            has voluntarily signed the document. 
                            Acknowledgments often are needed for documents concerning valuable assets, such as deeds, mortgages, 
                            and deeds of trust.
                            </p>

                            <img src={ElderlyCoupleImage} className={styles["services-image"]} alt="elderly couple at a signing"></img>

                            <h5 className={styles["sub-title"]}>Copy certification</h5>
                            <p className={styles["description"]}>
                                A copy certification confirms that a reproduction of an original document is a full, true, 
                                and accurate transcription or reproduction of the original.
                            </p>

                        </div>

                        <div className={styles["column-two"]}>
                            <h5 className={styles["sub-title"]}>Jurats</h5>
                            <p className={styles["description"]}>
                                The purpose of a jurat is for a signer to swear or affirm that the contents of a document are true. 
                                Depending on the jurisdiction, it also can be known as an affidavit or a verification on oath or affirmation.
                            </p>

                            <h5 className={styles["sub-title"]}>Oaths/affirmations</h5>
                            <p className={styles["description"]}>
                            Sometimes, a client may simply need you to administer an oath or affirmation orally rather than as part of a jurat​, affidavit, or other written document. 
                            The purpose of administering a verbal oath or affirmation is, again, to compel a client to truthfulness.
                            </p>
                        </div>
                    </div>
                </section>

                <section className={styles["loan-signing"]}>
                    <h3 className={styles["services-sub-title"]}>Loan Signing</h3>
                    <p className={styles["loan-paragraph"]}>
                    Whether you’re refinancing your home or embarking on a new purchase, 
                    Driven Mobile Notary Services is your trusted partner in ensuring timely delivery and signing of 
                    crucial loan documents. We act as a seamless bridge between you, your realtor, mortgage company, lender, 
                    and closing agent, ensuring every transaction progresses smoothly towards closing.
                    </p>

                    <p className={styles["loan-paragraph-two"]}>
                    We pledge to deliver not just expertise, but also resourcefulness and professionalism in every engagement. 
                    You deserve the best, and DMNS is here to provide just that!
                    </p>

                    <h4 className={styles["offered-title"]}>Services offered:</h4>

                    <div className={styles["services-list"]}>
                        <div className={styles["column-one"]}>
                            <ul>
                                <li className={styles["loan-signing-item"]}>Real Estate closing</li>
                                <li className={styles["loan-signing-item"]}>Refinance transactions</li>
                                <li className={styles["loan-signing-item"]}>Purchase transactions</li>
                                <li className={styles["loan-signing-item"]}>Reverse Mortgage</li>
                                <li className={styles["loan-signing-item"]}>Title and Escrow documents </li>
                                <li className={styles["loan-signing-item"]}>Deed of trust</li>
                                <li className={styles["loan-signing-item"]}>HELOC (Home Equity Line of Credit)</li>
                            </ul>
                        </div>

                        <div className={styles["column-two"]}>
                            <img src={WomanSigningImage} className={styles["loan-signing-image"]} alt="two people at a notary signing"></img>
                        </div>
                    </div>

                    <div className={styles["disclaimer-container"]}>
                        <p className={styles["disclaimer"]}>
                        “I AM NOT AN ATTORNEY IN THE STATE OF NEVADA. I AM NOT LICENSED TO GIVE LEGAL ADVICE. 
                        I MAY NOT ACCEPT FEES FOR GIVING LEGAL ADVICE” (NRS 240.085.1).
                        </p>
                    </div>
                </section>

                <section className={styles["frequently-asked-questions"]}>
                    <FrequentlyAskedQuestions/>
                </section>
            </div>

            <section className={styles["footer-section"]}>
                <Footer topRef={topRef} />
            </section>
        </motion.div>
    )
}

export default Services;

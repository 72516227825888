import React , { useRef}from 'react';
import styles from './Footer.module.css';
import mapIcon from '../images/map-icon.svg';
import phoneIcon from '../images/phone.svg';
import mailIcon from '../images/email-icon.svg';
import yelpIcon from '../images/yelp.svg';
import facebookIcon from '../images/facebook.svg';
import upArrow from '../images/up-arrow.svg';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';


const Footer = ({ topRef }) => {

    const location = useLocation();

    // Function to get class based on active state
    const getActiveLinkClass = (path) => {
        return location.pathname === path ? `${styles.link} ${styles.active}` : styles.link;
    };

    const scrollToTop = () => {
        if (topRef && topRef.current) {
            topRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const isHomePage = location.pathname === '/';

    // const handleNavLinkClick = () => {
    //     scrollToTop();
    // }

    return (
        <>
            <div className={styles["footer-container"]}>
                <div className={styles["main-container"]}>
                    <div className={styles["contact-container"]}>
                        <h3 className={styles["title"]}>Contact Us</h3>

                        <div className={styles["contact-details"]}>

                            <div className={styles["address"]}>
                                <img className={styles["icon"]} src={mapIcon}></img>
                                <p className={styles["business-address"]}>
                                    P.O Box 338163 <br></br>
                                    North Las Vegas, NV 89033
                                </p>
                            </div>

                            <div className={styles["phone"]}>
                                <img className={styles["icon"]} src={phoneIcon}></img>
                                <a className={styles["phone-number"]} href="tel:702-902-9550">(702)-902-9550</a>
                            </div>

                            <div className={styles["email"]}>
                                <img className={styles["icon"]} src={mailIcon}></img>
                                <a className={styles["email-address"]} href="mailto: contact@drivennotaryservices.com">contact@drivennotaryservices.com</a>
                            </div>
                                                
                        </div>

                    </div>

                    <div className={styles["navigation-container"]}>
                        <h3 className={styles["title"]}>Navigation</h3>
                        <Link to="/" className={getActiveLinkClass('/')}>Home</Link>
                        <Link to="/services" className={getActiveLinkClass('/services')}>Services</Link>
                        <Link to="/pricing" className={getActiveLinkClass('/pricing')}>Pricing</Link>
                        <Link to="/about" className={getActiveLinkClass('/about')}>About Us</Link>
                        <Link to="/contact" className={getActiveLinkClass('/contact')}>Contact</Link>
                    </div>

                    <div className={styles["social-container"]}>
                        <h3 className={styles["title"]}>Follow Us</h3>
                        <div className={styles["social-icons"]}>
                            <a href="https://www.yelp.com/biz/driven-mobile-notary-services-north-las-vegas" target="blank" ><img className={styles["yelp-icon"]} src={yelpIcon}></img></a>
                            <a href="https://www.facebook.com/drivenmobilenotaryservices/" target="blank"><img className={styles["facebook-icon"]} src={facebookIcon}></img></a>
                            
                            {isHomePage && (
                                <div className={styles["top-btn-container"]}>
                                    <button onClick={scrollToTop} className={styles["top-btn"]}>Back to top <img src={upArrow} className={styles["arrow"]}></img></button>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            
            
                <hr className={styles["horizontal-line"]}></hr>
            
                <div className={styles["copyright-details"]}>
                    <p className={styles["copyright"]}> © 2024 Driven Mobile Notary Services. All Rights Reserved.</p>

                    {isHomePage && (
                        <div className={styles["btn-container"]}>
                            <button onClick={scrollToTop} className={styles["desktop-top-btn"]} >Back to top <img src={upArrow} className={styles["arrow"]}></img></button>
                        </div>
                    )}
                </div>
            </div>
        </>

    )
}

export default Footer;
import React from 'react';
import styles from './Contact.module.css';
import InfoHeader from '../components/InfoHeader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import mapIcon from '../images/map-icon.svg';
import phoneIcon from '../images/phone.svg';
import emailIcon from '../images/email-icon.svg';
import { motion } from 'framer-motion';


function Contact() {
    return (
        <>
            <motion.div
            initial={{opacity: 0 }}
            animate={{opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}>

                <InfoHeader/>
                <Header/>

                <div className={styles["main-container"]}>
                    <h1 className={styles["contact-title"]}>Contact Us</h1>

                    <section className={styles["contact-us"]}>
                        <div className={styles["contact-content"]}>
                            <div className={styles["column-one"]}>
                                <p className={styles["concerns-text"]}>
                                    If you have questions or concerns, feel free to complete the form below
                                    to send us a message.
                                </p>

                                <p className={styles["security-message"]}>
                                    For security purposes, please do not disclose any sensitive information 
                                    regarding the document that will be notarized. 
                                </p>

                                <p className={styles["contact-requirements"]}>
                                    Please provide a brief description of the type of notarization required. Once contacted by our notary, 
                                    all details required to complete the notarization will be gathered at that time.
                                </p>

                                <div className={styles["contact-details"]}>
                                    <div className={styles["address"]}>
                                        <img src={mapIcon} className={styles["icon"]}></img>
                                        <p className={styles["mail-box"]}>P.O Box 338163 <br></br>  North Las Vegas, NV 89032 </p>             
                                    </div>

                                    <div className={styles["phone-number"]}>
                                        <img src={phoneIcon} className={styles["icon"]}></img>
                                        <a href="tel:702-902-9550" className={styles["number"]}>702-902-9550</a>
                                    </div>
                                    
                                    <div className={styles["email"]}>
                                        <img src={emailIcon} className={styles["icon"]}></img>
  
                                        <a href="mailto: contact@drivennotaryservices.com" className={styles["email-address"]}>contact@drivennotaryservices.com</a>

                                    </div>
                                </div>  
                            </div>
                            <div className={styles["column-two"]}>
                                <ContactForm/>
                            </div>
                        </div>

                        <div className={styles["mobile-google-maps"]}>
                            <iframe width="400" max-width="1000" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=North%20Las%20Vegas,%2089032%20+(Driven%20Mobile%20Notary%20Services)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=a515f773026f01c73ec01ed5e529d1b78da3b5c9'></script>
                        </div>

                        <div className={styles["small-google-maps"]}>
                            <iframe width="327" max-width="1000" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=North%20Las%20Vegas,%2089032%20+(Driven%20Mobile%20Notary%20Services)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=a515f773026f01c73ec01ed5e529d1b78da3b5c9'></script>
                        </div>

                        <div className={styles["medium-google-maps"]}>
                            <iframe width="800" max-width="1000" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=North%20Las%20Vegas,%2089032%20+(Driven%20Mobile%20Notary%20Services)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=a515f773026f01c73ec01ed5e529d1b78da3b5c9'></script>
                        </div>

                        <div className={styles["large-google-maps"]}>
                            <iframe width="1200" max-width="1000" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=North%20Las%20Vegas,%2089032%20+(Driven%20Mobile%20Notary%20Services)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=a515f773026f01c73ec01ed5e529d1b78da3b5c9'></script>
                        </div>



                    </section>
                </div>

                    <section className={styles["footer-section"]}>
                        <Footer/>
                    </section>


            </motion.div>


        </>
    )
}

export default Contact;
import React, { useState } from 'react';
import styles from './ContactForm.module.css';

function ContactForm() {
    const [isSubmitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
    
        const formData = new FormData(event.target);
    
        try {
          const response = await fetch('https://boiling-headland-56437-9b5c4bf3374a.herokuapp.com/send-email', {
            method: 'POST',
            body: formData, // or JSON.stringify(data) for JSON data
          });
    
          if (response.ok) {
            setSubmitted(true);
          } else {
            console.error('Server responded with status:', response.status);
          }
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        } finally {
          setIsSubmitting(false);
        }
    };
    

    return (
        <div className={styles["form-container"]}>
            {!isSubmitted ? (
                <form onSubmit={handleSubmit} className={styles["form-content"]}>
                    <div className={styles["name-field"]}>
                        <input type="text" id="fname" name="fname" placeholder="First name" required />

                        <input type="text" id="lname" name="lname" placeholder="Last name" required />
                    </div>

                    <div className={styles["email-field"]}>
                        <input type="email" id="email" name="email" placeholder="Email" required />
                    </div>

                    <div className={styles["subject-field"]}>
                        <input type="text" id="subject" name="subject" placeholder="Subject" required />
                    </div>

                    <div className={styles["message-field"]}>
                        <textarea id="message" name="message" placeholder="Your message" rows="10" required />
                    </div>

                    <div className={styles["button-container"]}>
                        <button 
                            type="submit" 
                            className={styles["submitButton"]} 
                            disabled={isSubmitting}
                        >
                        {isSubmitting ? 'Sending...' : 'Send Message'}
                         </button>
                    </div>
                    
                </form>
            ) : (
                <div className={styles["submitted-state"]}>
                    <p className={styles["thank-you"]}>Thank you!</p>
                    <br></br>
                    
                    <p className={styles["message-received"]}>We have received your message and will get back to you shortly.</p>
            
                </div>

            )}
                
        </div>
    );
}

export default ContactForm;

    

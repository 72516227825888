import React from 'react';
import styles from './Header.module.css';
import Sidebar from './Sidebar';
import logo from '../images/logo.png';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

const Header = () => {

    const location = useLocation();

    const getLinkClassName = (path) => {
        const isActive = location.pathname === path;
        const activeClass = isActive ? styles.activeLink : '';
        const nonHomeLinkClass = location.pathname !== '/' ? styles.nonHomeLink : '';
        return `${styles.link} ${activeClass} ${nonHomeLinkClass}`;
    };


    return (
        <>    
            <header className={styles["mobile-navigation"]}>
            <Link to="/"><img className={styles["mobile-logo-image"]} src={logo} alt="driven mobile notary services logo"></img></Link>
                <Sidebar/>
                
            </header>
            <div className={styles["desktop-nav"]}>
                <Link to="/"><img className={styles["logo-image"]} src={logo} alt="driven mobile notary services logo"></img></Link>
                            
                <nav className={styles["nav-links"]}>
                    <li><Link to="/" className={`${styles.link} ${getLinkClassName('/')}`}>Home</Link></li>
                    <li><Link to="/services" className={`${styles.link} ${getLinkClassName('/services')}`}>Services</Link></li>
                    <li><Link to="/pricing" className={`${styles.link} ${getLinkClassName('/pricing')}`}>Pricing</Link></li>
                    <li><Link to="/about" className={`${styles.link} ${getLinkClassName('/about')}`}>About Us</Link></li>
                    <li><Link to="/contact" className={`${styles.link} ${getLinkClassName('/contact')}`}>Contact</Link></li>
                    
                </nav>

                <div className={styles["book-appointment"]}>
                    <a href="https://drivennotaryservices.com/booking/schedule" className={styles["appointment-btn"]}>Book Appointment</a>
                </div>


                
            </div>
        </>
    )
}

export default Header;
